import React from "react";
import _ from "lodash";
import deliveryService from "../apis/deliveryService";
import { action, observable, runInAction } from "mobx";
import { LOADING_STATUS } from "../constants/loadingStatus";
import { IReaderToc, IReaderSections } from "../models/toc";
import { msalInstance } from "../authConfig";
import IHighlightMark from "models/highlightMark";
import { markExistingHighlights } from "components/reader/contentController/helpers/helpers";


interface IReaderStoreContent {
  content: any | IReaderToc | Array<IReaderSections>;
  isPreview: boolean;
  status?: LOADING_STATUS;
  responseCode?: number | undefined;
}

export interface ICallToActionButton {
  isEnabled: boolean;
  title: string;
  url: string;
}

export class ReaderStoreClass {
  @observable DesignationId: string | undefined;
  @observable Reader: IReaderStoreContent = {
    content: "",
    isPreview: false,
    status: LOADING_STATUS.EMPTY,
    responseCode: 0,
  };
  @observable Toc: IReaderStoreContent = {
    content: {},
    isPreview: false,
    status: LOADING_STATUS.EMPTY,
    responseCode: 0,
  };
  @observable ReaderSections: IReaderStoreContent = {
    content: [],
    isPreview: false,
    status: LOADING_STATUS.EMPTY,
    responseCode: 0,
  };
  @observable IsNavigating: boolean | undefined;
  @observable sectionsFetched: number[] = [];
  @observable contentLoadingStatus: LOADING_STATUS = LOADING_STATUS.EMPTY;

  // Copy of ReaderSections.content, as read from DB (reader search tags not added)
  private ReaderSectionsNoChangeCopy: Array<IReaderSections> = [];

  // isMobile has to be sent to the reader iframe from the reader layout,
  // because if checking for screen width in code inside the iframe, it only
  // takes into account the width of the iframe and indicates mobile mode even if
  // reader layout is not in mobile mode. Thus keep IsMobile in store and send to
  //  iframe along other data
  @observable IsMobile = true;

  @observable CallToActionButton : ICallToActionButton = {
    isEnabled: false,
    title: "",
    url: ""
  };

  constructor() {
    //this line of code binds 'this' to your function so you can use it
    this.SetReaderSection = this.SetReaderSection.bind(this);
    this.SetReaderToc = this.SetReaderToc.bind(this);
  }

  @action SetStatus = function (data: any) {
    switch (data) {
      case {} || "":
        return LOADING_STATUS.EMPTY;
      case undefined:
        return LOADING_STATUS.ERROR;
      default: {
        return data.error ? LOADING_STATUS.ERROR : LOADING_STATUS.READY;
      }
    }
  };


  @action SetReaderSection = async (designationId: string, previewOnly: boolean, version: string) => {
    try {
      if (this.ReaderSections.status === LOADING_STATUS.LOADING) return;
      this.ReaderSections.status = LOADING_STATUS.LOADING;
      this.contentLoadingStatus = LOADING_STATUS.LOADING;
      this.ReaderSections.content = [];

      let emptyResponse = false;
      let startingIndex = 1;
      this.sectionsFetched = [];
      //get sections via sectionList
      do {
        await deliveryService.fetchSections(designationId, previewOnly, startingIndex, version).then((response) => {
          runInAction(() => {
            if(response.status === 401)
            {
              this.LogoutUser();
            }
            this.sectionsFetched = this.sectionsFetched.concat(startingIndex);
            this.ReaderSections.status = this.SetStatus(response);
            this.ReaderSections.responseCode = response.status;
            if (response.data && response.data.sections.length > 0) {
              this.ReaderSections.content.push(...response.data.sections);

              // Also maintain a clean copy of the content
              this.ReaderSectionsNoChangeCopy.push(...response.data.sections);
              this.ReaderSections.isPreview = response.data.isPreview;
            } else {
              emptyResponse = true;
              this.ReaderSections.status = LOADING_STATUS.DONE;
              this.ReaderSections.isPreview = response.data.isPreview;
            }
            const lastItem = this.ReaderSections.content[this.ReaderSections.content.length - 1];
            if (!lastItem) {
              emptyResponse = true;
              return;
            }
            startingIndex = lastItem.index + 1;
            // check if the starting index isn't NaN
            if (Number.isNaN(startingIndex)) {
              emptyResponse = true;
              this.ReaderSections.status = LOADING_STATUS.DONE;
            }
          });
        });
      } while (!emptyResponse);
      runInAction(() => {
        this.contentLoadingStatus = LOADING_STATUS.DONE;
      });
    } catch (error) {
      runInAction(() => {
        this.ReaderSections.status = LOADING_STATUS.ERROR;
        this.contentLoadingStatus = LOADING_STATUS.ERROR;
        //set default
        this.ReaderSections.content = [];
        this.sectionsFetched = [];
      });
    }
    return null;
  };

  @action UpdateReaderSections = (newSectionContent: string, sectionId: number) => {
    runInAction(() => {
      // Find the appropriate section and update. Due to MobX limitation (not re-rendering when a property inside an array item changes),
      // update the whole array by replacing updated section
      const sectionIndexInArray = _.findIndex(this.ReaderSections.content, (s: any) => s.id === sectionId);

      if (sectionIndexInArray > -1) {
        const updatedSection = Object.assign({}, this.ReaderSections.content[sectionIndexInArray], {
          partialHtmlBody: newSectionContent,
        });

        this.ReaderSections.content.splice(sectionIndexInArray, 1, updatedSection);
      }
    });
  };

  @action ResetReaderSections = () => {
    runInAction(() => {
      this.ReaderSections.content = this.ReaderSectionsNoChangeCopy;
    });
  };

  @action ResetReaderSectionsWithHighlights = (highlights: IHighlightMark[]) => {
    runInAction(() => {
      this.ReaderSections.content = this.ReaderSectionsNoChangeCopy;

      highlights.forEach((highlight: any) => {
        const section = this.ReaderSections.content.filter((s: any) => highlight.sectionId === s.id)[0];
        
        if (!section) return;

        const $ = markExistingHighlights({
          sectionHtml: section.partialHtmlBody,
          sectionHighlights: [highlight],
          sectionId: section.id,
          isMobile: section.isMobile,
        });

        section.partialHtmlBody = $.html();
      });
    });
  };

  @action SetIsMobileMode = async (isMobile: boolean) => {
    runInAction(() => {
      this.IsMobile = isMobile;
    });
  };

  @action SetReaderToc = async (designationId: string, version: string) => {
    try {
      if (this.Toc.status === LOADING_STATUS.LOADING) return;
      this.Toc.status = LOADING_STATUS.LOADING;
      //set default
      this.Toc.content = undefined;
      this.Toc.responseCode = undefined;
      await deliveryService.fetchToc(designationId, version).then((response) => {
          runInAction(() => {
        if(response.status === 401)
          {
            this.LogoutUser();
          }
          this.Toc.status = this.SetStatus(response);
          this.Toc.responseCode = response.status;
          this.Toc.content = response.data || {};
          this.Toc.isPreview = response.data?.schema?.isPreview ?? true;
          this.DesignationId = response.data?.schema?.designation;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.Toc.status = LOADING_STATUS.ERROR;
        
        //set default
        this.Toc.content = {};
      });
    }
    return null;
  };

  @action SetIsNavigating = async (isNavigating: boolean) => {
    runInAction(() => {
      this.IsNavigating = isNavigating;
    });
  };

  @action reset = () => {
    runInAction(() => {
      this.DesignationId = undefined;
    });
  };

  @action LogoutUser = () => {
    runInAction(() => {
      msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
     return null;
    });
  };

  @action SetCallToActionButton = async (callToActionButton: ICallToActionButton) => {
    runInAction(() => {
      this.CallToActionButton = callToActionButton;
    });
  };
}

export default React.createContext(new ReaderStoreClass());
