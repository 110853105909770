import React, { useState, useRef, useContext, useEffect } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import {
  SearchBar,
  SearchContainer,
  SearchResultsPanel,
  SearchResultsContainer,
  ResultHeader,
  ResultItem,
  ResultSection,
  ResultContainer,
  ResultPhrase,
  ResultPreviewHeader,
  SearchBarClose,
  InfiniteScrollLoader,
} from "./style";
import { OutsideClickDetector } from "../../../../../helpers/outsideClickDetector";
import ReaderSearch from "./readerSearch";
import NoResultsMessage from "../../../../noResultsMessage/NoResultsMessage";
import { ScrollIntoViewViaIdOrClassName } from "../../../../../helpers/ScrollIntoView";
import ReaderLayoutContext, { IReaderLayoutContext } from "../../readerLayoutContext";
import ReaderStore from "./../../../../../stores/ReaderStore";
import { InfiniteScroll } from "../../../../../helpers/infiniteScroll";
import { Loader } from "../../../../../components/loader/loader";
import Icon from "../../../../icons";
import ICONS from "../../../../../constants/icons";
import STYLE_DEFAULTS from "../../../../../constants/styles";
import { SA_SPACING_FACTOR_3 } from "../../../../../constants/styles/spacing";
import useWindowDimensions from "../../../../../hooks/useWindowDimension";
import CONTENT_SEARCH_DEFAULTS from "../../../../../constants/contentSearch";
import TRACKING_EVENTS from "../../../../../services/segmentProvider/models/trackingEvents";
import * as segmentUtils from "utils/segmentUtils";
import highlightStore from "stores/highlightStore";

const scrollId = "infinite-scroll-id";

// Delay in msec between user last input change and search start
const SEARCH_TIMEOUT_MSEC = 250;

const setHighlight = (id: any) => {
  // Timeout is required as there is a delay between jumping to a location,
  // rendering will all necessary section styling. Since we are updating DOM, it is
  // overwritten when section styling is applied. Give a small timeout to compensate.
  // Alternatively, the HTML from reader store should be manipulated rather than DOM,
  // then delay will not be needed
  setTimeout(() => {
    // Deselect previous selection, should only be one, but treat as array in any case
    const arr = document.getElementsByClassName("search-result__clicked");

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index] as HTMLElement;
      element.classList.remove("search-result__clicked");
    }

    // Highlight current selection
    const searchItem = document.getElementById(id);
    searchItem?.classList.add("search-result__clicked");
  }, 500);
};

const navigateToSearchResultOnArrowKey = function (readerSearch: any, selectedIndex: any) {
  let result = null;
  let currentIndex = 0;
  for (const searchResults of readerSearch?.SearchResults) {
    for (const item of searchResults.results) {
      if (currentIndex === selectedIndex) {
        result = item;
        break;
      }
      currentIndex++;
    }
    if (result) {
      break;
    }
  }
  if (result) {
    ScrollIntoViewViaIdOrClassName(result.id, CONTENT_SEARCH_DEFAULTS.READER_CONTENT, 40);
    setHighlight(result.id);
  }
};

//SearchBox renders the search bar/box
//it handles the user input/logic of that data
const SearchBox = (props: any) => {
  const {
    userInput,
    setUserInput,
    dropdownOpen,
    setDropdown,
    isMobile,
    setIsMobileSearchOpen,
    designationId,
    selectedIndex,
    setSelectedIndex,
  } = props;

  const placeholderStr = designationId ? `Search within ${designationId}` : '';
  const readerSearchContext = useContext(ReaderSearch);
  const readerContext = useContext(ReaderStore);
  const highlightContext = useContext(highlightStore);

  // Indicates user entered search value
  const [searchRequested, setSearchRequested] = useState(false);

  // Allow small delay between user input and search start in case user continues to type
  // Search will actually begin when "searchRequested" is true and "searchWaitElapsed" is true
  // Each new input resets "searchWaitElapsed" to cause delay after input
  const [searchWaitElapsed, setSearchWaitElapsed] = useState(false);
  const [searchWaitTimeoutId, setSearchWaitTimeoutId] = useState(0);

  const resetSearchRequestAndDelay = (props: { isSearchRequested: boolean }) => {
    if (searchWaitTimeoutId) {
      window.clearTimeout(searchWaitTimeoutId!);
      setSearchWaitTimeoutId(0);
    }

    setSearchWaitElapsed(false);
    setSearchRequested(props.isSearchRequested);
  };

  //function that is called on users input
  function handleInputChange(newInput: any) {
    if (newInput.trim() === "") {
      setDropdown(false);
      resetSearchRequestAndDelay({ isSearchRequested: false });
      readerContext.ResetReaderSectionsWithHighlights(highlightContext.highlights)
    } else {
      // Reset search delay due to new input
      resetSearchRequestAndDelay({ isSearchRequested: true });

      setSearchWaitTimeoutId(
        window.setTimeout(() => {
          setSearchWaitElapsed(true);
          segmentUtils.trackSearch(TRACKING_EVENTS.READER.SEARCH.event, TRACKING_EVENTS.READER.SEARCH.category, newInput, newInput);
        }, SEARCH_TIMEOUT_MSEC),
      );
    }

    setUserInput(newInput);
  }

  //function that is called when the user clicks on the search bar/box
  function handleOnClick(newInput: any) {
    setDropdown(newInput !== "");
    setSearchRequested(newInput !== "");
  }

  //function that is called when the user presses down on a key
  function handleOnArrowPress(event: any) {
    const scrollToSelectedElement = function (direction: string, index: any) {
      const scrollBox = document.getElementById(scrollId);
      const selectedElement = document.getElementById("result-search-item-" + index);

      if (direction === "down" && selectedElement && selectedElement?.offsetTop > 400) {
        scrollBox?.scroll(0, selectedElement?.offsetTop - 120);
      }
      if (direction === "up" && selectedElement) {
        scrollBox?.scroll(0, selectedElement?.offsetTop - 120);
      }
    };

    if (event.key === "ArrowUp" && selectedIndex > 0) {
      event.preventDefault();
      const newIndex = selectedIndex - 1;
      setSelectedIndex(newIndex);
      scrollToSelectedElement("up", newIndex);
      navigateToSearchResultOnArrowKey(readerSearchContext, newIndex);
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();
      let maxCount = 0;
      readerSearchContext.SearchResults.filter((x: any) => (maxCount += x.results.length));
      if (selectedIndex < maxCount - 1) {
        const newIndex = selectedIndex + 1;
        setSelectedIndex(newIndex);
        scrollToSelectedElement("down", newIndex);
        navigateToSearchResultOnArrowKey(readerSearchContext, newIndex);
      }
    }
  }

  const segmentReference = useRef(null);

  useEffect(() => {
    if (searchRequested && searchWaitElapsed && userInput) {
      readerSearchContext.generateSearchResults(userInput, setDropdown);
      setSearchRequested(false);
    }
  }, [searchRequested, searchWaitElapsed]);

  useEffect(() => {
    readerSearchContext.setExistingHighlights(highlightContext.highlights);
    readerContext.ResetReaderSectionsWithHighlights(highlightContext.highlights)
  }, [highlightContext.highlights]);

  return (
    <SearchBar isMobile={isMobile}>
      <input
        ref={segmentReference}
        type="text"
        title="Reader search"
        value={userInput}
        placeholder={placeholderStr}
        onClick={(event) => handleOnClick(event.currentTarget.value)}
        onChange={(event) => handleInputChange(event.currentTarget.value)}
        onKeyDown={handleOnArrowPress}
        autoFocus={isMobile}
        onFocus={() => setSelectedIndex(-1)}
      />
      {(dropdownOpen || isMobile) && (
        <SearchBarClose>
          <Icon
            icon={ICONS.CLOSE}
            tiny
            onClick={() => {
              setSelectedIndex(0);
              setDropdown(false);
              if (isMobile) {
                setIsMobileSearchOpen(false);
              }
              // Clear the search text and results.
              readerSearchContext.generateSearchResults("", setDropdown);
              setUserInput("");
              resetSearchRequestAndDelay({ isSearchRequested: false });
            }}
            clickable
          />
        </SearchBarClose>
      )}
    </SearchBar>
  );
};

const renderReaderSearchItem = (
  item: any,
  setDropdown: any,
  setIsMobileSearchOpen?: any,
  currentIndex?: any,
  selectedIndex?: any,
  setSelectedIndex?: any,
) => {
  return (
    <ResultItem
      id={"result-search-item-" + currentIndex}
      selected={currentIndex === selectedIndex}
      key={item.id}
      onMouseEnter={() => setSelectedIndex(currentIndex)}
      onClick={() => {
        ScrollIntoViewViaIdOrClassName(item.id, CONTENT_SEARCH_DEFAULTS.READER_CONTENT, 40);
        setHighlight(item.id);
        if (setIsMobileSearchOpen) {
          setIsMobileSearchOpen(false);
          setDropdown(false);
        }
      }}
    >
      {item.before}
      <ResultPhrase>{item.phrase}</ResultPhrase>
      {item.after}
    </ResultItem>
  );
};

const renderReaderSearchSection = (
  item: any,
  setDropdown: any,
  setIsMobileSearchOpen?: any,
  currentIndex?: any,
  selectedIndex?: any,
  setSelectedIndex?: any,
) => {
  return (
    <ResultContainer key={item.section}>
      {item.section !== "" && <ResultSection>{item.section}</ResultSection>}
      {item.results.map((hit: any, index: any) => {
        return renderReaderSearchItem(
          hit,
          setDropdown,
          setIsMobileSearchOpen,
          currentIndex + index,
          selectedIndex,
          setSelectedIndex,
        );
      })}
    </ResultContainer>
  );
};

//based on input, generates results or
//no results message
const Hits: React.FC<any> = observer((props: any) => {
  const { userInput, isPreview, setDropdown, setIsMobileSearchOpen, selectedIndex, setSelectedIndex, isMobile } = props;
  const readerSearchContext = useContext(ReaderSearch);
  const showResults =
    (readerSearchContext.SearchResults && readerSearchContext.SearchResults.length > 0) ||
    readerSearchContext.MoreToLoad === true;
  const { height } = useWindowDimensions();
  if (showResults) {
    return (
      <InfiniteScroll
        elementId={scrollId}
        maxHeight={isMobile ? height - 64 : 450}
        loadMore={readerSearchContext.loadMoreSearchResults}
      >
        <ResultHeader>
          {readerSearchContext.SearchResults.map((hit: any, index: any) => {
            const currentIndex = index === 0 ? 0 : readerSearchContext.SearchResults[index - 1].runningTotal;
            return renderReaderSearchSection(
              hit,
              setDropdown,
              setIsMobileSearchOpen,
              currentIndex,
              selectedIndex,
              setSelectedIndex,
            );
          })}
        </ResultHeader>
        <InfiniteScrollLoader>
          {readerSearchContext.MoreToLoad && <Loader height={SA_SPACING_FACTOR_3} width={SA_SPACING_FACTOR_3} />}
        </InfiniteScrollLoader>
        {isPreview && (
          <ResultPreviewHeader>
            <Icon icon={ICONS.INFO} color={STYLE_DEFAULTS.COLORS.SA_BLUE} />
            To access more, you need to purchase the document.
          </ResultPreviewHeader>
        )}
      </InfiniteScroll>
    );
  } else {
    return <NoResultsMessage searchQuery={userInput} messageOverride="No Match Found" />;
  }
});

const ReaderSearchBar: React.FC<any> = observer((props: any) => {
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownContainerOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { isMobile, setIsMobileSearchOpen } = props;

  const context = useContext<IReaderLayoutContext>(ReaderLayoutContext);
  const readerSearchContext = useContext(ReaderSearch);
  const readerContext = useContext(ReaderStore);
  const highlightContext = useContext(highlightStore);

  OutsideClickDetector(wrapperRef, setDropdownContainerOpen);
  if (setIsMobileSearchOpen) {
    OutsideClickDetector(wrapperRef, setIsMobileSearchOpen);
  }

  useEffect(() => {
    // Provide HTML sections to search store and a function to update/reset sections in reader with added/removed tags
    readerSearchContext.initialiseUpdateReaderSectionsFn(readerContext.UpdateReaderSections);
    readerSearchContext.initialiseResetReaderSectionsFn(() => readerContext.ResetReaderSectionsWithHighlights(highlightContext.highlights));
    readerSearchContext.setReaderSectionsContentCopy(readerContext.ReaderSections.content);
  }, [context.readerStore.Toc.content?.tableOfContents]);
  return (
    <div ref={wrapperRef}>
      <SearchContainer onFocus={() => setDropdownContainerOpen(dropdownOpen)}>
        <SearchBox
          setUserInput={setUserInput}
          userInput={userInput}
          setIsMobileSearchOpen={setIsMobileSearchOpen}
          dropdownOpen={dropdownOpen}
          setDropdown={setDropdownContainerOpen}
          isMobile={isMobile}
          designationId={context.readerStore.DesignationId}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        {/* only show drop down when dropdownOpen is true*/}
        {dropdownOpen && (
          <SearchResultsContainer isMobile={isMobile}>
            <SearchResultsPanel id={scrollId}>
              <Hits
                setDropdown={setDropdownContainerOpen}
                setIsMobileSearchOpen={setIsMobileSearchOpen}
                userInput={userInput}
                isPreview={context.isPreview}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                isMobile={isMobile}
              />
            </SearchResultsPanel>
          </SearchResultsContainer>
        )}
      </SearchContainer>
    </div>
  );
});

export default ReaderSearchBar;
